import React from 'react';

// Starterpack blocks
import {
	// atoms
	ButtonAnchor,
	ButtonFilter,
	FormSectionBreaker,
	Heading,
	Introduction,
	List,
	Paragraph,
	InputEmail,
	InputFile,
	InputSelect,
	InputText,
	InputTextarea,
	InputYesNo,
	// molecules
	CardAddress,
	// CardCta,
	CardFeature,
	CardKeyfactIcon,
	CardKeyfactText,
	CardIconText,
	CardLogo,
	// CardPartner,
	CardPeople,
	CardResource,
	Column,
	Columns,
	Image,
	ImageResponsive,
	// ListAccordions,
	Form,
	// organisms
	PageHeaderSimple,
	PageHeader,
	PageHeaderTwoColumns,
	SectionTwoColumnsCol,
	// SectionNews,
	// dynamic (starterpack)
	ListAddress,
	ListButtons,
	ListButtonsAnchor,
	ListCtas,
	ListFeatures,
	ListIconText,
	ListKeyfactsIcon,
	ListKeyfactsText,
	ListLogos,
	ListPeople,
	ListResources,
	SectionButtonsAnchor,
	// -- GENERATOR CORE BLOCKS IMPORT SLOT --
} from '@superhuit/starterpack-blocks/renders';

// Custom blocks
// prettier-ignore
import {
	// dynamic (custom)
	// ---
	Accordion,
	Alert,
	ButtonPrimary,
	ButtonSecondary,
	CardArtist,
	CardCalendar,
	CardCta,
	CardDateTime,
	CardDateTour,
	CardEvent,
	CardPartner,
	CardProduction,
	CardRole,
	CardShow,
	FalcDescription,
	HeaderA,
	HeaderB,
	HeaderC,
	HTML,
	HomeCalendar,
	ListAccordions,
	ListCalendar,
	ListArtists,
	ListEvents,
	ListHighlightedArtists,
	ListHighlightedProductions,
	ListFiles,
	ListPartners,
	ListProductions,
	ListShows,
	ListShowsEvents,
	Quote,
	SectionArtistsSlider,
	SectionCardsArtist,
	SectionCardsEvent,
	SectionCardsShow,
	SectionCardsPartner,
	SectionCardsPress,
	SectionCardsProduction,
	SectionCtas,
	SectionDetails,
	SectionDocumentation,
	SectionFaq,
	SectionHighlightedArtists,
	SectionHighlightedProductions,
	SectionPartners,
	SectionPressSlider,
	SectionRichContent,
	SubsectionQuote,
	SubsectionCenteredText,
	SubsectionTwoImages,
	SubsectionBigTextImages,
	SubsectionGallery,
	SubsectionKeywordImage,
	SubsectionTextMedia,
	SubsectionLeadingArtists,
	SubsectionHeader,
	HeaderListAnchor,
	HeaderMetas,
	HeaderPracticalInfo,
	Tag,
	SectionDatesTimes,
	SectionDatesTour,
	SectionSubscription,
	CardSubscription,
	CardShop,
	ListShop,
	SectionCardsShop,
	Video,
	Media,
	// -- GENERATOR CUSTOM BLOCKS IMPORT SLOT --
} from '@/components/renders';

const blocksList = {
	// core
	[Heading.slug]: Heading,
	[List.slug]: List,
	[HTML.slug]: HTML,
	// supt
	// atoms
	[Accordion.slug]: Accordion,
	[ButtonAnchor.slug]: ButtonAnchor,
	[ButtonFilter.slug]: ButtonFilter,
	[ButtonPrimary.slug]: ButtonPrimary,
	[ButtonSecondary.slug]: ButtonSecondary,
	[Image.slug]: Image,
	[ImageResponsive.slug]: ImageResponsive,
	[Introduction.slug]: Introduction,
	[Paragraph.slug]: Paragraph,
	[InputEmail.slug]: InputEmail,
	[InputFile.slug]: InputFile,
	[InputSelect.slug]: InputSelect,
	[InputText.slug]: InputText,
	[InputTextarea.slug]: InputTextarea,
	[InputYesNo.slug]: InputYesNo,
	[HeaderPracticalInfo.slug]: HeaderPracticalInfo,
	[Tag.slug]: Tag,
	// cards
	[CardAddress.slug]: CardAddress,
	[CardCta.slug]: CardCta,
	[CardFeature.slug]: CardFeature,
	[CardIconText.slug]: CardIconText,
	[CardKeyfactIcon.slug]: CardKeyfactIcon,
	[CardKeyfactText.slug]: CardKeyfactText,
	[CardLogo.slug]: CardLogo,
	[CardPartner.slug]: CardPartner,
	[CardPeople.slug]: CardPeople,
	[CardProduction.slug]: CardProduction,
	[CardResource.slug]: CardResource,
	// molecules
	[Alert.slug]: Alert,
	[Column.slug]: Column,
	[Columns.slug]: Columns,
	[FalcDescription.slug]: FalcDescription,
	[Form.slug]: Form,
	[FormSectionBreaker.slug]: FormSectionBreaker,
	[ListAccordions.slug]: ListAccordions,
	[HeaderListAnchor.slug]: HeaderListAnchor,
	[HeaderMetas.slug]: HeaderMetas,
	// organisms
	[PageHeaderSimple.slug]: PageHeaderSimple,
	[PageHeader.slug]: PageHeader,
	[PageHeaderTwoColumns.slug]: PageHeaderTwoColumns,
	[SectionCardsProduction.slug]: SectionCardsProduction,
	// [SectionNews.slug]: SectionNews,
	// dynamic (starterpack)
	[ListAddress.slug]: ListAddress,
	[ListButtons.slug]: ListButtons,
	[ListButtonsAnchor.slug]: ListButtonsAnchor,
	[ListCtas.slug]: ListCtas,
	[ListFeatures.slug]: ListFeatures,
	[ListIconText.slug]: ListIconText,
	[ListKeyfactsIcon.slug]: ListKeyfactsIcon,
	[ListKeyfactsText.slug]: ListKeyfactsText,
	[ListLogos.slug]: ListLogos,
	[ListPeople.slug]: ListPeople,
	[ListResources.slug]: ListResources,
	[SectionButtonsAnchor.slug]: SectionButtonsAnchor,
	// dynamic (custom)
	[CardArtist.slug]: CardArtist,
	[CardCalendar.slug]: CardCalendar,
	[CardDateTime.slug]: CardDateTime,
	[CardDateTour.slug]: CardDateTour,
	[CardEvent.slug]: CardEvent,
	[CardPartner.slug]: CardPartner,
	[CardRole.slug]: CardRole,
	[CardShow.slug]: CardShow,
	[HeaderA.slug + '-left']: SectionTwoColumnsCol,
	[HeaderA.slug + '-right']: SectionTwoColumnsCol,
	[HeaderA.slug]: HeaderA,
	[HeaderB.slug]: HeaderB,
	[HeaderC.slug]: HeaderC,
	[HomeCalendar.slug]: HomeCalendar,
	[ListCalendar.slug]: ListCalendar,
	[ListArtists.slug]: ListArtists,
	[ListEvents.slug]: ListEvents,
	[ListFiles.slug]: ListFiles,
	[ListHighlightedArtists.slug]: ListHighlightedArtists,
	[ListHighlightedProductions.slug]: ListHighlightedProductions,
	[ListPartners.slug]: ListPartners,
	[ListProductions.slug]: ListProductions,
	[ListShows.slug]: ListShows,
	[ListShowsEvents.slug]: ListShowsEvents,
	[Quote.slug]: Quote,
	[SectionArtistsSlider.slug]: SectionArtistsSlider,
	[SectionCtas.slug]: SectionCtas,
	[SectionDetails.slug + '-col']: SectionTwoColumnsCol,
	[SectionDetails.slug]: SectionDetails,
	[SectionCardsArtist.slug]: SectionCardsArtist,
	[SectionCardsEvent.slug]: SectionCardsEvent,
	[SectionCardsPress.slug]: SectionCardsPress,
	[SectionCardsPartner.slug]: SectionCardsPartner,
	[SectionCardsShow.slug]: SectionCardsShow,
	[SectionDocumentation.slug + '-col']: SectionTwoColumnsCol,
	[SectionDocumentation.slug]: SectionDocumentation,
	[SectionHighlightedArtists.slug]: SectionHighlightedArtists,
	[SectionHighlightedProductions.slug]: SectionHighlightedProductions,
	[SectionFaq.slug + '-left']: SectionTwoColumnsCol,
	[SectionFaq.slug + '-right']: SectionTwoColumnsCol,
	[SectionFaq.slug]: SectionFaq,
	[SectionPartners.slug]: SectionPartners,
	[SectionPressSlider.slug]: SectionPressSlider,
	[SectionRichContent.slug]: SectionRichContent,
	[SubsectionQuote.slug]: SubsectionQuote,
	[SubsectionCenteredText.slug]: SubsectionCenteredText,
	[SubsectionTwoImages.slug]: SubsectionTwoImages,
	[SubsectionBigTextImages.slug]: SubsectionBigTextImages,
	[SubsectionGallery.slug]: SubsectionGallery,
	[SubsectionKeywordImage.slug]: SubsectionKeywordImage,
	[SubsectionTextMedia.slug]: SubsectionTextMedia,
	[SubsectionLeadingArtists.slug]: SubsectionLeadingArtists,
	[SubsectionHeader.slug]: SubsectionHeader,
	[SectionDatesTimes.slug]: SectionDatesTimes,
	[SectionDatesTour.slug]: SectionDatesTour,
	[SectionSubscription.slug]: SectionSubscription,
	[CardSubscription.slug]: CardSubscription,
	[CardShop.slug]: CardShop,
	[ListShop.slug]: ListShop,
	[SectionCardsShop.slug]: SectionCardsShop,
	[Video.slug]: Video,
	[Media.slug]: Media,
	// -- GENERATOR BLOCKLIST SLOT --
};

export default function PostBodyBlocks({
	blocks,
	includes = /.*/g,
	excludes = /^$/g,
	isRoot = true,
}) {
	return (
		<>
			{blocks.map(({ name, ...props }, i) => {
				if (
					!!blocksList[name] &&
					new RegExp(includes).test(name) &&
					!new RegExp(excludes).test(name)
				) {
					const Block = blocksList[name];
					// check if section is the first one on the page to define its title Tag (h1 or h2)
					if (isRoot && props.attributes) {
						const titleTag = i === 0 ? 'h1' : 'h2';
						props.attributes.titleTag = titleTag;
					}

					return (
						<Block key={i} {...props.attributes} slug={name}>
							{props.innerBlocks && (
								<PostBodyBlocks
									blocks={props.innerBlocks}
									isRoot={false}
								/>
							)}
						</Block>
					);
				} else {
					// if (
					// 	[
					// 		'supt/page-header',
					// 		'supt/page-header-simple',
					// 		'supt/page-header-two-columns',
					// 	].includes(name)
					// ) {
					// 	// do nothing here, this is handled in the single-post / single-page layouts
					// 	return null;
					// }
					// FALLBACK: means the block is not yet implemented in next.js
					// if (process.env.NODE_ENV === 'development') {
					// 	console.warn(
					// 		`The following block does not exist: ${name}. This typically happens if you forget to import and render it in blocks.js. See source code for more info & solutions.`
					// 	);
					// }
					// NOTE: if you want to silence this warning for a specific block
					// (let's say you just don't want to render it), copy the following
					// lines before the above warning and replace the name by your block's name:
					// if (name === 'name_of_your_block') {
					// 	return null;
					// }
					return null;
				}
			})}
		</>
	);
}
